import React from 'react'
import { Link } from 'gatsby'

class PrenotaVisitaTabs extends React.Component {

  render() { 
    const tabSelected = this.props.data;

    return (
      <div className="emergenze">
          <img src="../images/contents/icona_emergenza.png" alt="Plus ico" className="emergenze__ico d-none d-sm-block" />
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <Link to="/prenota-p/" title="Piccoli animali" className={tabSelected==="cani-gatti"?"emergenza emergenza--active emergenza--piccolianimali ":"emergenza emergenza--piccolianimali"}>
                  <span className="emergenza__title">Piccoli animali</span>
                  <span className="emergenza__phone">0250334116</span>
                  <span className="emergenza__address">Via dell'Università 6, Lodi</span>
                </Link>
              </div>
              <div className="col-12 col-lg-4">
                <Link to="/prenota-c/" title="Cavalli"className={tabSelected==="cavalli"?"emergenza emergenza--active emergenza--cavalli":"emergenza emergenza--cavalli"}>
                  <span className="emergenza__title">Cavalli</span>
                  <span className="emergenza__phone">3299388472</span>
                  <span className="emergenza__address">Via dell'Università 6, Lodi</span>
                </Link>
              </div>
              <div className="col-12 col-lg-4">
                <Link to="/prenota-r/" title="Ruminanti e suini" className={tabSelected==="suini"?"emergenza emergenza--active emergenza--ruminanti":"emergenza emergenza--ruminanti"}>
                  <span className="emergenza__title">Ruminanti e suini</span>
                  <span className="emergenza__phone">3341064108</span>
                  <span className="emergenza__address">Via dell'Università 6, Lodi</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
    )
  }

}

export default PrenotaVisitaTabs;